<template>
    <div class="penalty-detail-helper-row">
        <div class="label">
            <slot></slot>
        </div>
        <div class="pernalty-setter">
            <slot name="penalty-setter">
                <b-checkbox switch v-model="Value" :disabled="readonly" size="lg"></b-checkbox>
            </slot>
        </div>
    </div>
</template>

<script>
export default {
    model:{
        prop: 'value',
        event: 'change'
    },
    emits:['change'],
    props:{
        value: {type: Boolean, default:false},
        readonly: {type: Boolean, default: false},
    },
    data(){
        return {
            d_value: this.value,
            d_readonly: this.readonly
        };
    },
    computed:{
        Value:{
            get(){ return this.d_value;},
            set(newValue){
                this.$emit('change', newValue);
            }
        },
        Readonly(){ return this.d_readonly;}
    },
    watch:{
        value(newValue){
            this.d_value = newValue;
        },
        readonly(newValue){
            this.d_readonly = newValue;
        }
    }
}
</script>

<style scoped>

.penalty-detail-helper-row{
    display:flex;
    justify-content: space-between;
    align-items: center;
    padding-left:5px;
    padding-right:1px;
    min-height: 40px;
}
.penalty-detail-helper-row .pernalty-setter{
    display:flex !important;
    justify-content: center !important;
    align-content: center !important;
    min-height: 40px;
    flex-wrap: wrap;
}
</style>

